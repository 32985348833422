<template>
  <div>
    <Navbar text="Mise à jour de la base de données 🔄 !"/>


    <div class="container is-max-desktop">

  
      <div v-if="buffers">

        <div class="card card-content" v-for="(buffer, index) of buffers" :key=index>
              <p class="content is-medium" style="font-weight: bold">Une nouvelle mise à jour !</p>
              <p class="content">Pour l'idcc : <span style="font-weight:bold"> {{buffer.idcc}} </span> | Salariés entre <span style="font-weight:bold">{{buffer.minEmployees}} </span>
                et <span style="font-weight:bold">{{buffer.maxEmployees}}</span> | Budget en db : <span style="font-weight:bold">{{buffer.oldBudget}}</span> | 
                  Nouveau budget : <span style="font-weight:bold">{{buffer.newBudget}}</span></p>
              <a v-if="buffer && buffer.dataLink" :href=buffer.dataLink target="_blank">Lien pour accéder à la source </a>
              <div style="margin-top: 15px" class="columns">
                <div class="column is-1"></div>
                <div class="column">
                  <b-button type="is-danger" @click="acceptOrDeclineUpdate(false, buffer._id)" label="Refuser"/> 
                </div>
                <div class="column">
                  <b-button type="is-primary" @click="acceptOrDeclineUpdate(true, buffer._id)" label="Accepter"/> 
                </div>
                <div class="column">
                  <b-button type="is-info" label="Repporter un bug (pop up)"/> 
                </div>
                <div class="column is-1"></div>
              
              </div>
        </div>

      </div>



      <div class="columns">
        <div class="column">
          <b-field label="Mettre à jour les informations d'un idcc">
            <b-autocomplete
                v-model="name"
                placeholder="e.g. 1486"
                :keep-first="keepFirst"
                :open-on-focus="openOnFocus"
                :data="filteredDataObj"
                field="idcc"
                @select="option => (selected = option)"
                :clearable="clearable"
            >
              <template slot-scope="props">
                <div class="container">
                  <p>
                    <b>IDCC:</b>
                    {{props.option.idcc}}
                  </p>
                  <p>
                    <b>Salariés:</b>
                    De {{props.option.minEmployees ? props.option.minEmployees : "NA"}} à {{props.option.maxEmployees ? props.option.maxEmployees : "NA"}}
                  </p>
                </div>
              </template>
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column" >
          <p style="margin-left: 120px; margin-top: 40px">OU</p>
        </div>
        <div class="column">
          <br/>
          <b-button @click="adding = true" style="margin-top: 8px" rounded type="is-info">Ajouter un IDCC inexistant ➕</b-button>
        </div>
      </div>




        <div v-if="selected || adding">
          <p class="content" v-if="!adding">
            <b>Selection:</b> <br/>
            Dernière mise à jour : {{selected ? new Date(selected.updateDate).toLocaleDateString() : ""}}
          <b-field>
            <b-checkbox v-model="forAllOpco">Appliquer le changement à tout l'ocpo : {{opco && opco.opcoName ? opco.opcoName : ""}} </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="forAllIdcc">Appliquer le changement à tout l'idcc </b-checkbox>
          </b-field>
          
          </p>
          <b-field label="IDCC">
            <b-input v-model="myUpdate.idcc" :placeholder="selected ? selected.idcc : '' " :disabled="!adding"></b-input>
          </b-field>
          <b-field label="Nombre de salarié">
            De <b-input v-model="myUpdate.minEmployees" :placeholder="selected ? selected.minEmployees : '' " style="margin-left: 5px; margin-right: 5px" ></b-input>
            à <b-input v-model="myUpdate.maxEmployees" :placeholder="selected ? selected.maxEmployees : '' " style="margin-left: 5px; margin-right: 5px" ></b-input>
          </b-field>
          <b-field label="Affichage">
            <b-select placeholder="Affichage" v-model="myUpdate.broadcastable">
                <option value="En externe">En externe</option>
                <option value="En interne">En interne</option>
            </b-select>
          </b-field>
          <b-field label="Dispositif">
            <b-input v-model="myUpdate.facility" :placeholder="selected ? selected.facility : '' "></b-input>
          </b-field>
         <b-field label="Date de mise à jour opco">
            <b-datepicker
                icon="calendar-today"
                :v-model="new Date(myUpdate.updateOcpoDate)"
                editable>
            </b-datepicker>
          </b-field>

          <p> Cout pédagogique</p>

          <b-field label="Montant du plan de développement">
            <b-input v-model="myUpdate.budget" :placeholder="selected ? selected.budget : '' "></b-input>
          </b-field>
          <b-field label="Général / Afest">
            <b-input v-model="myUpdate.generalAfest" :placeholder="selected ? selected.generalAfest : '' "></b-input>
          </b-field>
          <b-field label="Plafond horaire">
            <b-input v-model="myUpdate.hourlyCap" :placeholder="selected ? selected.hourlyCap : '' "></b-input>
          </b-field>
          <b-field label="Plafond horaire Afest">
            <b-input v-model="myUpdate.AfestHourlyCap" :placeholder="selected ? selected.AfestHourlyCap : '' "></b-input>
          </b-field>
          <b-field label="Plafond journalier">
            <b-input v-model="myUpdate.dailyCap" :placeholder="selected ? selected.dailyCap : '' "></b-input>
          </b-field>
          <b-field label="Plafond volume horaire">
            <b-input v-model="myUpdate.hourlyVolumeCap" :placeholder="selected ? selected.hourlyVolumeCap : '' "></b-input>
          </b-field>
          <b-field label="Plafond volume horaire afest">
            <b-input v-model="myUpdate.afestHourlyVolumeCap" :placeholder="selected ? selected.afestHourlyVolumeCap : '' "></b-input>
          </b-field>
          <b-field label="Minimum volume horaire">
            <b-input v-model="myUpdate.minHourlyVolume" :placeholder="selected ? selected.minHourlyVolume : '' "></b-input>
          </b-field>
          <b-field label="Plafond de prise en charge">
            <b-input v-model="myUpdate.capRateOfCoverage" :placeholder="selected ? selected.capRateOfCoverage : '' "></b-input>
          </b-field>

          <p> Plan TPE</p>

          <b-field label="Budget">
            <b-input v-model="myUpdate.budgetTPE" :value="selected.budgetTPE" :placeholder="selected ? selected.budgetTPE : '' "></b-input>
          </b-field>
          <b-field label="Général / Afest">
            <b-input v-model="myUpdate.generalAfestTPE" :placeholder="selected ? selected.generalAfestTPE : '' "></b-input>
          </b-field>
          <b-field label="Plafond horaire">
            <b-input v-model="myUpdate.hourlyCapTPE" :placeholder="selected ? selected.hourlyCapTPE : '' "></b-input>
          </b-field>
          <b-field label="Minimum volume horaire">
            <b-input v-model="myUpdate.minHourlyVolumeTPE" :placeholder="selected ? selected.minHourlyVolumeTPE : '' "></b-input>
          </b-field>
          <b-field label="Plafond de prise en charge">
            <b-input v-model="myUpdate.capRateOfCoverageTPE" :placeholder="selected ? selected.capRateOfCoverageTPE : '' "></b-input>
          </b-field>
          <b-field label="Conditions">
            <b-input maxlength="200" type="textarea" v-model="myUpdate.conditionsTPE" :placeholder="selected ? selected.conditionsTPE : '' "></b-input>
          </b-field>

          <p> FNE</p>

          <b-field label="Budget">
            <b-input v-model="myUpdate.budgetFNE" :placeholder="selected ? selected.budgetFNE : '' "></b-input>
          </b-field>
          <b-field label="Deadline">
            <b-input v-model="myUpdate.deadLineTPE" :placeholder="selected ? selected.deadLineTPE : '' "></b-input>
          </b-field>

          <p> Frais annexes</p>

          <b-field label="Salaire par heure">
            <b-input v-model="myUpdate.salaryEuroPerHour" :placeholder="selected ? selected.salaryEuroPerHour : '' "></b-input>
          </b-field>
          <b-field label="Restauration">
            <b-input v-model="myUpdate.restauration" :placeholder="selected ? selected.restauration : '' "></b-input>
          </b-field>
          <b-field label="Hotellerie">
            <b-input v-model="myUpdate.hosting" :placeholder="selected ? selected.hosting : '' "></b-input>
          </b-field>
          <b-field label="Transport">
            <b-input v-model="myUpdate.transport" :placeholder="selected ? selected.transport : '' "></b-input>
          </b-field>
          


          <b-field label="Exclusion">
            <b-input v-model="myUpdate.exclusion" :placeholder="selected ? selected.exclusion : '' "></b-input>
          </b-field>

          <b-field>
            <b-checkbox v-model="myUpdate.rh">Rh</b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="myUpdate.poec">Poec</b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="myUpdate.poei">Poei</b-checkbox>
          </b-field>
          <b-field label="Autre">
            <b-input v-model="myUpdate.other" :placeholder="selected ? selected.other : '' "></b-input>
          </b-field>


          <b-field label="Explications supplémentaire">
            <b-input maxlength="200" type="textarea" v-model="myUpdate.details" :placeholder="selected ? selected.details : '' "></b-input>
          </b-field>
          <br/>
          <b-button v-if="!adding" rounded type="is-info" @click="updatingIdcc(selected._id)" >Mettre à jour</b-button>
          <b-button v-else rounded type="is-info" @click="addingIdcc()">Ajouter</b-button>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>


    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import {getAllIdcc} from "@/utils/idcc/getAllIdcc";
import {getAllBufferPendding} from "@/utils/buffer/getAllBufferPendding";
import {addIdcc} from "@/utils/idcc/addIdcc";
import {updateIdcc} from "@/utils/idcc/updateIdcc";
import {acceptOrDeclineBuffer} from "@/utils/buffer/acceptOrDeclineBuffer";
import {getOpcoByIdcc} from "@/utils/opco/getOpcoByIdcc";

export default {
  name: "Update",
  components: {
    Navbar
  },
  data() {
    return {
      data : null,
      keepFirst: false,
      openOnFocus: true,
      name: '',
      selected: null,
      clearable: true,
      myUpdate : {idcc : null,
        minEmployees : null,
        maxEmployees : null,
        budget : null,
        hourlyCap : null,
        broadcastable : null,
        facility : null,
        updateDate : null,
        updateOcpoDate : null,
        generalAfest : null,
        AfestHourlyCap : null,
        hourlyVolumeCap : null,
        afestHourlyVolumeCap : null,
        minHourlyVolume : null,
        capRateOfCoverage : null,
        budgetTPE : null,
        generalAfestTPE : null,
        hourlyCapTPE : null,
        minHourlyVolumeTPE : null,
        capRateOfCoverageTPE : null,
        conditionsTPE : null,
        budgetFNE : null,
        deadLineTPE : null,
        salaryEuroPerHour : null,
        restauration : null,
        hosting : null,
        transport : null,
        exclusion : null,
        rh : null,
        poec : null,
        poei : null,
        other : null,
        details : null,
        dailyCap : null,
        hourlySupported : null},
      adding : false,
      buffers :null,
      forAllOpco : false,
      forAllIdcc : false,
      opco : null
      
    };
  },

  mounted() {
    getAllIdcc().then(res=>{
      this.data = res.data.sort(function(a, b) {
        return parseFloat(a.idcc) - parseFloat(b.idcc);
      }).sort(function(a, b) {
        return parseFloat(a.minEmployees) - parseFloat(b.minEmployees);
      });
    });

    getAllBufferPendding().then(res=>{
      this.buffers = res.data
    });
  },

  watch : {

    selected : function (){
      this.idcc = this.selected.idcc;
      this.minEmployees = this.selected.minEmployees;
      this.maxEmployees = this.selected.maxEmployees;
      this.budget = this.selected.budget;
      this.hourlyCap = this.selected.hourlyCap;
      this.dailyCap = this.selected.dailyCap;
      this.hourlySupported = this.selected.hourlySupported;
      this.details = this.selected.details;
      this.broadcastable = this.selected.broadcastable;
      this.facility = this.selected.facility;
      this.updateDate = this.selected.updateDate;
      this.updateOcpoDate = new Date(this.selected.updateOcpoDate);
      this.generalAfest = this.selected.generalAfest;
      this.AfestHourlyCap = this.selected.AfestHourlyCap;
      this.hourlyVolumeCap = this.selected.hourlyVolumeCap;
      this.afestHourlyVolumeCap = this.selected.afestHourlyVolumeCap;
      this.minHourlyVolume = this.selected.minHourlyVolume;
      this.capRateOfCoverage = this.selected.capRateOfCoverage;
      this.budgetTPE = this.selected.budgetTPE;
      this.generalAfestTPE = this.selected.generalAfestTPE;
      this.hourlyCapTPE = this.selected.hourlyCapTPE;
      this.minHourlyVolumeTPE = this.selected.minHourlyVolumeTPE;
      this.capRateOfCoverageTPE = this.selected.capRateOfCoverageTPE;
      this.conditionsTPE = this.selected.conditionsTPE;
      this.budgetFNE = this.selected.budgetFNE;
      this.deadLineTPE = this.selected.deadLineTPE;
      this.salaryEuroPerHour = this.selected.salaryEuroPerHour;
      this.restauration = this.selected.restauration;
      this.hosting = this.selected.hosting;
      this.transport = this.selected.transport;
      this.exclusion = this.selected.exclusion;
      this.rh = this.selected.rh;
      this.poec = this.selected.poec;
      this.poei = this.selected.poei;
      this.other = this.selected.other;
      this.affec(this.myUpdate, this.selected)
      getOpcoByIdcc(this.idcc).then(res => {
        if(this.idcc && this.idcc.length>3 && res.data[0] === undefined){
          this.opco = {opcoName : "Nous ne connaissons pas l'opco de cet idcc merci de nous contacter."}
        }else{
          this.opco=res.data[0]
        }

      }).catch(e => {
        console.log(e)
      })
    }

    

  },

  methods : {
    addingIdcc(){
      if(this.idcc, this.minEmployees, this.maxEmployees, this.budget){
        addIdcc(this.idcc, this.minEmployees, this.maxEmployees, this.budget, this.hourlyCap, this.details, this.dailyCap, this.hourlySupported,this.broadcastable,this.facility
       ,this.generalAfest,this.AfestHourlyCap,this.hourlyVolumeCap,this.afestHourlyVolumeCap,this.minHourlyVolume,this.capRateOfCoverage,
        this.budgetTPE,this.generalAfestTPE,this.hourlyCapTPE,this.minHourlyVolumeTPE,this.capRateOfCoverageTPE,this.conditionsTPE,this.budgetFNE,this.deadLineTPE,this.salaryEuroPerHour,
        this.restauration,this.hosting,this.transport,this.exclusion,this.rh,this.poec,this.poei,this.other).then(res=>{
          console.info(res);
          this.notificationSuccess("Idcc ajouté avec succes")
        }).catch(e=>{
          this.notificationError(e)
        }).finally(()=>{
          getAllIdcc().then(res=>{
            this.data = res.data.sort(function(a, b) {
              return parseFloat(a.idcc) - parseFloat(b.idcc);
            }).sort(function(a, b) {
              return parseFloat(a.minEmployees) - parseFloat(b.minEmployees);
            });
          })
        })
      }else{
        this.notificationError("Merci de saisir au moins les informations : idcc, tranche de salariés et budget")
      }

    },

    affec(obj1, obj2) {
        Object.keys(obj1).forEach(key => {
          if(obj1[key] == null){
            obj1[key] = obj2[key]
          }
        });
    },
    
    diff(obj1, obj2){
      let keyFound = {};
      Object.keys(obj1).forEach(key => {
        if(obj1[key] !== obj2[key]){
          keyFound[key] = obj1[key];
        }
      });
      return keyFound || -1;

    },

    updatingIdcc(id){
      const changement =  this.diff(this.myUpdate, this.selected)
      if(this.idcc, this.minEmployees, this.maxEmployees, this.budget, id){
        if(changement){
          updateIdcc(this.idcc,changement,this.forAllOpco, this.forAllIdcc, this.opco.opcoName, id).then(res=>{
            console.info(res);
            this.notificationSuccess("Idcc mis à jour avec succes")
          }).catch(e=>{
            this.notificationError(e)
          }).finally(()=>{
            getAllIdcc().then(res=>{
              this.data = res.data.sort(function(a, b) {
                return parseFloat(a.idcc) - parseFloat(b.idcc);
              }).sort(function(a, b) {
                return parseFloat(a.minEmployees) - parseFloat(b.minEmployees);
              });
            })
          })
        }else{
          this.notificationError("Aucun changement détecté...")
        }
      }else{
        this.notificationError("Merci de saisir au moins les informations : idcc, tranche de salariés et budget")
      }

    },

    acceptOrDeclineUpdate(accept, id){
      acceptOrDeclineBuffer(id, accept).then(res =>{
        console.info(res);
        getAllBufferPendding().then(res=>{
          this.buffers = res.data
        })
      })
    }



  },

  computed: {
    filteredDataObj() {
      if(this.data){
        return this.data.filter(option => {
          return (
              option.idcc
                  .toString()
                  .toLowerCase()
                  .indexOf(this.name.toLowerCase()) >= 0
          )
        })
      }else{
        return []
      }

    }
  }

}
</script>
