import axios from "axios";
import {tokenHeaders} from "../http_common";
let burl = process.env.VUE_APP_API;

export function addIdcc(idcc, minEmployees, maxEmployees, budget, hourlyCap, details, dailyCap, hourlySupported,broadcastable,facility
    ,generalAfest,AfestHourlyCap,hourlyVolumeCap,afestHourlyVolumeCap,minHourlyVolume,capRateOfCoverage,
     budgetTPE,generalAfestTPE,hourlyCapTPE,minHourlyVolumeTPE,capRateOfCoverageTPE,conditionsTPE,budgetFNE,deadLineTPE,salaryEuroPerHour,
     restauration,hosting,transport,exclusion,rh,poec,poei,other){
    return axios.post(burl + '/api/idcc/add',{
        idcc, minEmployees, maxEmployees, budget, hourlyCap, details, dailyCap, hourlySupported,broadcastable,facility
       ,generalAfest,AfestHourlyCap,hourlyVolumeCap,afestHourlyVolumeCap,minHourlyVolume,capRateOfCoverage,
        budgetTPE,generalAfestTPE,hourlyCapTPE,minHourlyVolumeTPE,capRateOfCoverageTPE,conditionsTPE,budgetFNE,deadLineTPE,salaryEuroPerHour,
        restauration,hosting,transport,exclusion,rh,poec,poei,other
    },{
        headers : tokenHeaders
    });
}
