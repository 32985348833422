import axios from "axios";
import {tokenHeaders} from "../http_common";
let burl = process.env.VUE_APP_API;


export function acceptOrDeclineBuffer(id, accept){
    return axios.put(burl + '/api/buffer/update?id=' + id,{
        accept
    },{
        headers : tokenHeaders
    });
}
