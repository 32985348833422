import axios from "axios";
import {tokenHeaders} from "../http_common";
let burl = process.env.VUE_APP_API;


export function getAllBufferPendding(){
    return axios.get(burl + '/api/buffer/all',{
        headers : tokenHeaders
    });
}
